<template>
  <div class="com-container">
    <h3>各水库设备分布情况</h3>
    <div class="com-chart" id="main"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  data () {
    return {
      webData: null,
      myChart: null,
      pieColor: [
        '#0096ff',
        '#32c5e9',
        '#9fe6b8',
        '#ff9f7f',
        '#60cda0',
        '#006cff',
        '#ed8884',
        '#1d9dff'
      ],
      pieChartData: []
    }
  },
  mounted () {
    this.pieChartData = this.$store.state.webData.pieChartData
    this.initChart()
    // console.log(this.echarts.version)
  },
  methods: {

    initChart () {
      var chartDom = document.getElementById('main')
      this.myChart = echarts.init(chartDom)

      const option = {
        color: this.pieColor, // 设置颜色
        tooltip: {
          trigger: 'item',
          confine: true, // 提示内容不会显示一半
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },

        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 0,
          top: 10,
          bottom: 20,
          // data: data.legendData
          // bottom: '0%',
          itemWidth: 10,
          itemHeight: 6,
          // 下面三条注释为原来的横向标签
          // bottom: '0%',
          // itemWidth: 10,
          // itemHeight: 10,
          textStyle: {
            color: 'rgba(255,255,255,.5)',
            fontSize: '12'
          }
        },

        series: [
          {
            name: '设备分布',
            type: 'pie',
            minAngle: 5, // 最小的扇区角度（0 ~ 360）
            avoidLabelOverlap: true,
            radius: ['20%', '60%'],
            center: ['46%', '50%'],
            roseType: 'radius',
            // 图形的文字标签
            label: {
              fontSize: 11,
              show: true,
              position: 'outside', // 或其他合适的位置
              // rotate: -45, // 旋转45度显示
              formatter: function (params) {
                // 根据实际情况处理文本，如添加换行符
                let text = params.name
                if (text.length > 5) { // 假设标签名长度超过10时进行换行
                  text = text.slice(0, 4) + '\n' + text.slice(5)
                }
                return text
                // return text + ' ' + params.percent + '%'
              }
            },
            // 链接图形和文字的线条
            labelLine: {
              // length 链接图形的线条
              length: 6,
              // length2 链接文字的线条
              length2: 8
            },
            data: this.pieChartData // 设置饼图的数据源
          }
        ]
      }
      option && this.myChart.setOption(option)

      this.myChart.on('click', (params) => {
        // 点击饼图 形成事件返回项目名称
        // console.log('params', params)
        console.log(params.name)
        // 根据项目名称，更新vuex中的MapData数据，表格组件监测到数据变化，自动重新加载
        this.$store.commit('newProjectNameFromPie', params.name) // 将点击的项目名称传递给vuex，表格中根据名称更新 mapData_placed,mapData_alerted,mapData_handled数据
        // console.log(' store.state.projectNameFromPie 123', this.$store.state.projectNameFromPie)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.com-chart{
  right:0px
}
</style>
