<template>
  <div class="com-container">
    <div class="tmap-btn">
      <button  class="btn-left"  @click= "set1">卫星图</button>
      <button  class="btn-right"  @click= "set2">矢量图</button>
    </div>
    <div class="com-chart" ref="tmap_ref">
      <p id="title"></p>
      <p id="projectDevId"></p>
      <p id="placedTime"></p>
      <p id="alertedTime"></p>
      <p id="handledTime"></p>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      webData: null,
      TMapInstance: null,
      markerGeoArr: [],
      // geoAreaCenter:
      //   {
      //     新安江: [29.471534, 119.284838],
      //     洋溪: [29.516074, 119.340214],
      //     更楼: [29.444608, 119.271399],
      //     莲花: [29.566704, 119.309365],
      //     乾潭: [29.6166, 119.545457],
      //     梅城: [29.54121, 119.504161],
      //     杨村桥: [29.565291, 119.435706],
      //     下涯: [29.535377, 119.375447],
      //     大洋: [29.440262, 119.512303],
      //     三都: [29.537162, 119.5735],
      //     寿昌: [29.359206, 119.221878],
      //     航头: [29.33227, 119.184409],
      //     大慈岩: [29.288564, 119.297695],
      //     大同: [29.306849, 119.099329],
      //     李家: [29.323248, 119.039662],
      //     钦堂: [29.712833, 119.567363]
      //   },
      TMapGeoCenter: [],
      mapData_placed: [],
      mapData_alerted: null,
      mapData_handled: null,
      mapMarker_placed: [],
      mapMarker_alerted: [],
      mapMarker_handled: []
    }
  },
  computed: {
    geoCenter () {
      return this.$store.state.TMapGeoCenter
    }
  },
  mounted () {
    this.initTMapGeoCenter()
    this.mapData_placed = this.$store.state.webData.mapData_placed
    this.mapData_alerted = this.$store.state.webData.mapData_alerted
    this.mapData_handled = this.$store.state.webData.mapData_handled
    this.loadMap(this.mapData_placed, this.mapData_alerted, this.mapData_handled)
  },
  methods: {
    initTMapGeoCenter () {
      this.$store.state.TMapGeoCenter = this.$store.state.webData.TMapGeoCenter
    },
    loadMap (mapDataPlaced, mapDataAlerted, mapDataHandled) {
      // 测试 直接加map代码
      // 定义地图中心点坐标
      var center = new window.TMap.LatLng(this.geoCenter[0], this.geoCenter[1])
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new window.TMap.Map(this.$refs.tmap_ref, {
        center: center, // 设置地图中心点坐标
        zoom: 17.2, // 设置地图缩放级别
        pitch: 43.5, // 设置俯仰角
        rotation: 45, // 设置地图旋转角度
        // viewMode:'2D'
        mapStyleId: 'style1' // 地图样式
      })

      // 地图上加图片
      // var imageSW = new window.TMap.LatLng(29.471001, 119.272005)
      // var imageNE = new window.TMap.LatLng(29.472501, 119.273505)
      // var imageLatLngBounds = new window.TMap.LatLngBounds(imageSW, imageNE)
      // var imageGroundLayer = new window.TMap.ImageGroundLayer({
      //   bounds: imageLatLngBounds,
      //   src: 'https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/test/g2.jpg?sign=193f27b73b09f7aa837ce7c376a00cf2&t=1624201948',
      //   map: map
      // })
      // console.log(imageGroundLayer)
      // var imageSW2 = new window.TMap.LatLng(29.472501, 119.272005)
      // var imageNE2 = new window.TMap.LatLng(29.492501, 119.292505)
      // var imageLatLngBounds2 = new window.TMap.LatLngBounds(imageSW2, imageNE2)
      // var imageGroundLayer2 = new window.TMap.ImageGroundLayer({
      //   bounds: imageLatLngBounds2,
      //   src: 'https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/test/zhiying1.jpg?sign=a46ff62c2820cb3754753952b14aeb7c&t=1624202948',
      //   map: map
      // })
      // console.log(imageGroundLayer2)

      window.map = map
      // 修改地图中心点
      // map.setCenter(new window.TMap.LatLng(29.735197, 120.254830));

      // 地图标记参数配置
      // 已投放标记
      var markerArrPlaced = mapDataPlaced.map((item, index) => {
        return {
          id: 'id' + item.dev_id,
          styleId: 'marker1',
          position: new window.TMap.LatLng(item.Form_Use[0].location[0], item.Form_Use[0].location[1]),
          properties: {
            title: `产品号:${item.dev_id}`,
            projectName: `项目: ${item.Project[0].name}`,
            // projectDevId: `项目内编号: ${item.Form_Use[0].projectDevId}`,
            placed_location: `经纬度: ${item.Form_Use[0].location[1]} , ${item.Form_Use[0].location[0]}`,
            placed_time: `投放日期: ${this.formatDate(item.Form_Use[0].time)}`
          }
        }
      })

      // 已报警标记
      var markerArrAlerted = mapDataAlerted.map((item, index) => {
        return {
          id: 'id' + item.dev_id,
          styleId: 'marker2',
          position: new window.TMap.LatLng(item.Form_Use[0].location[0], item.Form_Use[0].location[1]),
          properties: {
            title: `产品号:${item.dev_id}`,
            projectName: `项目: ${item.Project[0].name}`,
            // projectDevId: `项目内编号: ${item.Form_Use[0].projectDevId}`,
            placed_location: `经纬度: ${item.Form_Use[0].location[1]} , ${item.Form_Use[0].location[0]}`,
            placed_time: `投放日期: ${this.formatDate(item.Form_Use[0].time)}`,
            alerted_time: `报警日期: ${this.formatDate(item.alerted_times[0])}`
          }
        }
      })

      // 已处理标记
      var markerArrHandled = mapDataHandled.map((item, index) => {
        return {
          id: 'id' + item.dev_id,
          styleId: 'marker3',
          position: new window.TMap.LatLng(item.Form_Use[0].location[0], item.Form_Use[0].location[1]),
          properties: {
            title: `产品号:${item.dev_id}`,
            projectName: `项目: ${item.Project[0].name}`,
            // projectDevId: `项目内编号: ${item.Form_Use[0].projectDevId}`,
            placed_location: `经纬度: ${item.Form_Use[0].location[1]} , ${item.Form_Use[0].location[0]}`,
            placed_time: `投放日期: ${this.formatDate(item.Form_Use[0].time)}`,
            alerted_time: `报警日期: ${this.formatDate(item.alerted_times[0])}`,
            handled_time: `处理日期: ${this.formatDate(item.Form_Handled[0].time)}`
          }
        }
      })
      // 已处理(重复监测）标记7.10
      var markerArrHandledRedoPlaced2 = mapDataHandled.map((item, index) => {
        return {
          id: 'id' + item.dev_id,
          styleId: 'markerRedoPlaced2',
          position: new window.TMap.LatLng(item.Form_Use[0].location[0], item.Form_Use[0].location[1]),
          properties: {
            title: `产品号:${item.dev_id}`,
            projectName: `项目: ${item.Project[0].name}`
            // // projectDevId: `项目内编号: ${item.Form_Use[0].projectDevId}`,
            // placed_location: `经纬度: ${item.Form_Use[0].location[1]} , ${item.Form_Use[0].location[0]}`,
            // placed_time: `投放日期: ${this.formatDate(item.Form_Use[0].time)}`,
            // alerted_time: `报警日期: ${this.formatDate(item.alerted_times[0])}`,
            // handled_time: `处理日期: ${this.formatDate(item.Form_Handled[0].time)}`
          }
        }
      })
      console.log('mapData1', mapDataPlaced)
      console.log('mapData2', mapDataAlerted)
      console.log('mapData3', mapDataHandled)

      // 初始化marker1
      var marker1 = new window.TMap.MultiMarker({
        id: 'placed',
        map: map,
        styles: {
          marker1: new window.TMap.MarkerStyle({
            // width: 40,
            // height: 56,
            width: 30,
            height: 42,
            anchor: { x: 16, y: 32 },
            src: require('@/assets/images/location-placed.png')
          })
        },
        geometries: markerArrPlaced
      })
      // console.log('marker1', marker1)

      // 初始化marker2
      var marker2 = new window.TMap.MultiMarker({
        id: 'alerted',
        map: map,
        styles: {
          marker2: new window.TMap.MarkerStyle({
            width: 30,
            height: 42,
            anchor: { x: 16, y: 32 },
            src: require('@/assets/images/location-alerted.png')
          })
        },
        geometries: markerArrAlerted
      })
      // console.log('marker2', marker2)

      // 初始化marker3
      var marker3 = new window.TMap.MultiMarker({
        id: 'handled',
        map: map,
        styles: {
          marker3: new window.TMap.MarkerStyle({
            width: 30,
            height: 42,
            anchor: { x: 16, y: 32 },
            src: require('@/assets/images/location-handled.png')
          })
        },
        geometries: markerArrHandled
      })

      // 初始化marker_redo_placed2
      var markerRedoPlaced2 = new window.TMap.MultiMarker({
        id: 'handled',
        map: map,
        styles: {
          marker3: new window.TMap.MarkerStyle({
            width: 30,
            height: 42,
            anchor: { x: 16, y: 32 },
            src: require('@/assets/images/redo/2/location-placed.png')
          })
        },
        geometries: markerArrHandledRedoPlaced2
      })

      // 初始化infoWindow
      var infoWindow = new window.TMap.InfoWindow({
        map: map,
        position: new window.TMap.LatLng(39.984104, 116.307503),
        offset: { x: 0, y: -32 } // 设置信息窗相对position偏移像素
      })
      infoWindow.close()// 初始关闭信息窗关闭
      // 监听标注点击事件
      marker1.on('click', function (evt) {
        // 设置infoWindow
        infoWindow.open() // 打开信息窗
        infoWindow.setPosition(evt.geometry.position)// 设置信息窗位置
        infoWindow.setContent('<div style = "font-size:0.225rem;color:#1989fa;text-align:left;line-height:25px;">' + evt.geometry.properties.title + '<br>' + evt.geometry.properties.projectName + '<br>' + evt.geometry.properties.placed_location + '<br>' + evt.geometry.properties.placed_time + '</div>')// 设置信息窗内容
        // infoWindow.setContent('<div style = "background: #1989fa">' + evt.geometry.properties.title + '<br>' + evt.geometry.properties.projectDevId + '<br>' + evt.geometry.properties.placed_time + '</div>')// 设置信息窗内容
      })

      marker2.on('click', function (evt) {
        // 设置infoWindow
        infoWindow.open() // 打开信息窗
        infoWindow.setPosition(evt.geometry.position)// 设置信息窗位置
        infoWindow.setContent('<div  style = "font-size:0.225rem;color:#ee0a24;text-align:left;line-height:25px;">' + evt.geometry.properties.title + '<br>' + evt.geometry.properties.projectName + '<br>' + evt.geometry.properties.placed_location + '<br>' + evt.geometry.properties.placed_time + '<br>' + evt.geometry.properties.alerted_time + '</div>')// 设置信息窗内容
        // infoWindow.setContent(evt.geometry.position.toString())// 设置信息窗内容
        // 点击设备标注 有图片内容
        // infoWindow.setContent(evt.geometry.properties.title + "<div><img src='https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/em.jpg'><p style = 'background:#000;color:red' >Hello World!</p></div>")// 设置信息窗内容
      })
      marker3.on('click', function (evt) {
        // 设置infoWindow
        infoWindow.open() // 打开信息窗
        infoWindow.setPosition(evt.geometry.position)// 设置信息窗位置
        infoWindow.setContent('<div  style = "font-size:0.225rem;color:#07c160;text-align:left;line-height:25px;">' + evt.geometry.properties.title + '<br>' + evt.geometry.properties.projectName + '<br>' + evt.geometry.properties.placed_location + '<br>' + evt.geometry.properties.placed_time + '<br>' + evt.geometry.properties.alerted_time + '<br>' + evt.geometry.properties.handled_time + '</div>')// 设置信息窗内容
        // 点击设备标注 有图片内容
        // infoWindow.setContent(evt.geometry.properties.title + "<div><img src='https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/em.jpg'><p style = 'background:#000;color:red' >Hello World!</p></div>")// 设置信息窗内容
      })
      markerRedoPlaced2.on('click', function (evt) {
        // 设置infoWindow
        infoWindow.open() // 打开信息窗
        infoWindow.setPosition(evt.geometry.position)// 设置信息窗位置
        infoWindow.setContent('<div  style = "font-size:0.225rem;color:#07c160;text-align:left;line-height:25px;">' + evt.geometry.properties.title + '<br>' + evt.geometry.properties.projectName + '<br>' + evt.geometry.properties.placed_location + '<br>' + evt.geometry.properties.placed_time + '<br>' + evt.geometry.properties.alerted_time + '<br>' + evt.geometry.properties.handled_time + '</div>')// 设置信息窗内容
        // 点击设备标注 有图片内容
        // infoWindow.setContent(evt.geometry.properties.title + "<div><img src='https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/em.jpg'><p style = 'background:#000;color:red' >Hello World!</p></div>")// 设置信息窗内容
      })
    },
    // 动态设置底图类型 卫星图或矢量图
    set1 () {
      window.map.setBaseMap({ type: 'satellite' })
      console.log('satellite')
    },
    set2 () {
      console.log('vector')
      window.map.setBaseMap({ type: 'vector' })
    },
    // 转化时间戳
    formatDate (datetime) {
      if (!datetime) {
        return
      }
      const dl = datetime.toString().length
      if (dl === 10) { datetime = datetime * 1000 }
      var date = new Date(datetime)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var sdate = ('0' + date.getDate()).slice(-2)
      var hour = ('0' + date.getHours()).slice(-2)
      var minute = ('0' + date.getMinutes()).slice(-2)
      // var second = ('0' + date.getSeconds()).slice(-2)
      // 拼接
      var result = year + '-' + month + '-' + sdate + ' ' + hour + ':' + minute
      // var result = year + "-"+ month +"-"+ sdate +" "+ hour +":"+ minute +":" + second;
      // 返回
      return result
    }

  },

  watch: {
    // 监听 store里面的数据 中心点
    '$store.state.TMapGeoCenter': {
      deep: true,
      handler: function (newValue, oldValue) {
        // 点击模块地图中的乡镇 改变地图的中心点
        window.map.setCenter(new window.TMap.LatLng(this.geoCenter[0], this.geoCenter[1]))
      }
    },
    '$store.state.webData.mapData_placed': {
      deep: true,
      handler: function (newValue, oldValue) {
        // 点击模块地图中的乡镇 改变地图的中心点
        window.map.destroy()
        this.mapData_placed = this.$store.state.webData.mapData_placed
        this.mapData_alerted = this.$store.state.webData.mapData_alerted
        this.mapData_handled = this.$store.state.webData.mapData_handled
        this.loadMap(this.mapData_placed, this.mapData_alerted, this.mapData_handled)
      }
    }
  }

}
</script>

<style lang="less" scoped>
// button{
//   z-index: 100;
// }
.tmap-btn {
            position: absolute;
            // position: fixed;
            width: 3rem;
            height: .4rem;
            top: .125rem;
            z-index: 1005;
            padding: 3px 5px;
            font-size: .175rem;
            .btn-left {
                width: 1rem;
                left: .125rem;
                background: cadetblue;
                color: white;
                border-radius: 30%;
            }
            .btn-right {
                width: 1rem;
                left: 1.6rem;
                background: cadetblue;
                color: white;
                border-radius: 30%;
            }
        }
</style>
