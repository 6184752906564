<template>
  <!-- <div class="detail" v-if="showDetail"> -->
  <div class="detail" v-show="showDetail">
    <!-- 退出登陆按钮 -->
    <div :class="btnStyle" @click="onClose" @mouseenter="enter" @mouseleave="leave">
      关闭浮窗
    </div>
    <!-- 设备详情 -->
    <div class="content">
      <h3 @click="onShowRecorder">设备号： <b class="value">{{devId}}</b>
        <span class="state">状态：<b class="value">{{showState}}</b></span>
        <span class="state" v-if="detailInfo.maintained_list">维护次数：<b class="value">{{detailInfo.maintained_list ? detailInfo.maintained_list.length : 0 }}</b></span>
        <!-- <span class="state" v-if="showRecorder">{{showRecorder ? "显示记录" : "隐藏记录"}}</span> -->
      </h3>
      <div class="recorder-box" v-show="!showRecorder">
        <div class="remarks" v-if="detailInfo.placed_remarks">
          <b class="value">施工记录：</b>{{detailInfo.placed_remarks}}
          <span class="recorde-name">{{detailInfo.placed_userName}}[{{detailInfo.placed_phone}}]</span>
        </div>
        <div class="remarks" v-if="detailInfo.maintained_list">
          <div class="state"><b class="value">维护记录：</b></div>
          <div v-for="(item,index) in detailInfo.maintained_list" v-bind:key = " index">
            <div class="maintain-list" v-if="item.remarks">
              {{index+1}}、{{item.remarks}}
              <span class="recorde-name">[{{formatDate(item.time)}}]</span>
            </div>
          </div>
        </div>
        <div class="remarks" v-if="detailInfo.handled_remarks">
          <b class="value">处理记录：</b>{{detailInfo.handled_remarks}}
          <span class="recorde-name">{{detailInfo.handled_userName}}[{{detailInfo.handled_phone}}]</span>
        </div>
          </div>
      </div>

    <div class="log-images">
      <el-carousel :interval="4000" type="card" height="8rem" autoplay>
        <el-carousel-item v-for="(item, index) in detailImgList" :key="index">
          <!-- <h3 class="medium">{{ item }}</h3> -->
            <el-image fit="cover" :src="item" style="width: 100%; height: 100%;" :preview-src-list="detailImgList">
            <!-- <el-image fit="cover" :src="item" style="width: 100%; height: 100%;" :preview-src-list="[]"> -->
            </el-image>
            <!-- fits: ['fill', 'contain', 'cover', 'none', 'scale-down'] -->
            <!-- <img class="cover" :src="item" @click="clickPhoto(item)"/> -->
            <!-- <img class="cover" :src="item" /> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div><img class="cover" :src="currentImgUrl" /></div> -->
  </div>
</template>

<script>
import cloudbase from '@cloudbase/js-sdk'
const app = cloudbase.init({
  env: 'bytube119-1g0lt4yo8ba6465c'
})
export default {
  data () {
    return {
      showDetail: false,
      devId: null,
      btnStyle: 'btn',
      detailImgList: [],
      currentImgUrl: '',
      detailInfo: {},
      showRecorder: false
    }
  },

  mounted () {
    this.devId = this.$store.state.devId
  },
  computed: {
    showState () {
      let stateStr = ''
      if (this.detailInfo.state === 'placed') {
        stateStr = '在线'
      } else if (this.detailInfo.state === 'alerted') {
        stateStr = '报警'
      } else if (this.detailInfo.state === 'handled') {
        stateStr = '处理'
      }
      return stateStr
    }
    // showRecorder () {
    //   if (this.detailInfo.maintained_list.length > 0 || this.detailInfo.placed_remarks || this.detailInfo.handled_content) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
  methods: {
    formatDate (datetime) {
      if (!datetime) {
        return
      }
      const dl = datetime.toString().length
      if (dl === 10) { datetime = datetime * 1000 }
      var date = new Date(datetime)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var sdate = ('0' + date.getDate()).slice(-2)
      // var hour = ('0' + date.getHours()).slice(-2)
      // var minute = ('0' + date.getMinutes()).slice(-2)
      // var second = ('0' + date.getSeconds()).slice(-2)
      // 拼接
      var result = year + '-' + month + '-' + sdate
      // var result = year + '-' + month + '-' + sdate + ' ' + hour + ':' + minute
      // var result = year + "-"+ month +"-"+ sdate +" "+ hour +":"+ minute +":" + second;
      // 返回
      return result
    },
    // 关闭浮窗
    onClose () {
      this.showDetail = false
    },
    // 鼠标经过和离开时的样式变量值
    enter: function () {
      // console.log('enter');
      console.log('detailObj', this.detailObj)
      this.btnStyle = 'overBtn'
    },
    leave: function () {
      // console.log('leave');
      this.btnStyle = 'btn'
    },
    getDetailData (devId) {
      app.callFunction({
        name: 'webLogin',
        data: {
          $url: 'getDetailData100', // 版本1.0.0适用
          devId: devId
        }
      }).then(res => {
        console.log('1、返回设备', devId, '详情：------------', res.result.data)
        // 返回数据错误时（没有图片地址）
        if (res.result.data.detailImgUrls.errCode === -501007) {
          // this.showDetail = false
          this.showDetail = true
          this.detailImgList = []
        } else {
          // 将数组转化成只留访问地址
          const detailImgList = res.result.data.detailImgUrls.fileList.map(item => item.tempFileURL)
          console.log('设备详情图片地址：', detailImgList)
          this.detailImgList = detailImgList
          // this.showRecorder = this.detailInfo.maintained_list.length > 0 || this.detailInfo.placed_remarks || this.detailInfo.handled_content
          this.showRecorder = true
          this.showDetail = true
        }
        // 返回单个设备的信息
        this.detailInfo = res.result.data.detailInfo
        console.log('detailInfo', this.detailInfo)
      })
    },
    clickPhoto (currentImgUrl) {
      this.currentImgUrl = currentImgUrl
      console.log('666', this.currentImgUrl)
    },
    // 点击显示或隐藏记录
    onShowRecorder () {
      this.showRecorder = !this.showRecorder
    }
  },
  watch: {
    // 监听 store里面的数据 devId 如果不为空则 显示浮窗并获取数据
    '$store.state.devId': {
      deep: true,
      handler: function (newValue, oldValue) {
        // 点击模块地图中的乡镇 改变地图的中心点
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)
        if (newValue) {
          this.getDetailData(newValue)
          this.devId = newValue
        } else {
          this.showDetail = false
        }
      }
    }
  }

}
</script>

<style lang='less' scoped>
.detail-box{
  height: 980px;
  z-index: 1000;
  }
.detail{
    position: absolute;
    width: 90%;
    height: 9rem;
    top: 5.5rem;
    left: 50%;
    transform: translate(-50%,-50%);
    /* color: #90959b83; */
    padding: 10px 20px;
    // background: linear-gradient(to top,#a0f7e1,#05a771);
    // background: linear-gradient(to top,#a0f7e13f,#05a7713b);
    background: linear-gradient(to bottom,#013668d7,#1c418550);
    color: #fff;
    font-size: 0.225rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0 20px 50px rgba(70, 69, 69, 0.5);
    overflow: hidden;
    z-index: 1001;
    .content{
      position: absolute;
      left: .25rem;
      top: .25rem;
      z-index: 1101;
      h3{
        color: #37caf7;
        margin-bottom: .125rem;
        z-index: 1200;
      .state{
        color: #37caf7;
        margin-left: .375rem;
        }
      }
      .recorder-box{
        width: 5.3rem;
        height: 8rem;
        overflow-y: auto;
        .remarks{
          color: #fcfcfc;
          background-color: #2972fa93;
          margin-left: .125rem;
          padding: .125rem;
          // color: #02f7f786;
          .recorde-name{
            color: #d3d7d886;
            font-size: .175rem;
          }
          .maintain-list{
            margin: .125rem 0 .125rem .125rem;

          }
        }
      }
      .value{
        color: #ffeb7b;
      }

    }
    .log-images{
      position: absolute;
      left: 0rem;
      top: 0rem;
      z-index: 1100;
      padding: .625rem .375rem 0 .375rem;
      width: 100%;
      background: #1c418500;
      // display: table-cell;
      // text-align: center;
      // vertical-align: middle;
      .el-carousel__item {
        // color: #475669;
        // font-size: 14px;
        opacity: 1;
        // line-height: 200px;
        // background:#1c418500;
        // margin: 0;
        // display: table-cell;
        // text-align: center;
        // vertical-align: middle;
      }
      // .el-carousel__item:nth-child(2n) {
      //   // background-color: #85adf85d;
      //   opacity: 0.5;
      //   // background: #1c418500;
      //   // display: table-cell;
      //   // text-align: center;
      //   // vertical-align: middle;
      // }
      // .el-carousel__item:nth-child(2n+1) {
      //   opacity: 0.5;
      //   // background-color: #85adf85d;
      //   // background: #1c418550;
      //   // display: table-cell;
      //   // text-align: center;
      //   // vertical-align: middle;
      // }
      // .el-carousel__mask{
      //   opacity: 0.5;
      // }
      // .is-active{
      //   opacity: 1;
      // }
      // .el-carousel__item--card{
      //   opacity: 1;
      // }
      // .is-in-stage{
      //   opacity: 1;
      // }
      // .is-hover{
      //   opacity: 1;
      // }
      // .is-animating{
      //   opacity: 1;
      // }

    }
}
.btn{
  position: absolute;
  right: 0rem;
  top: 0rem;
  line-height: 0.3rem;
  color: rgb(12, 91, 209);
  font-size: .225rem;
  text-align: center;
  width: 1.4rem;
  height: .3rem;
  background: #02f7f7ad;
  z-index: 1200;
}
.overBtn{
  position: absolute;
  right: 0rem;
  top: 0rem;
  line-height: 0.3rem;
  color: rgba(214, 16, 16, 0.87);
  font-size: .225rem;
  text-align: center;
  width: 1.4rem;
  height: .3rem;
  font: bolder;
  background: #02f7f7f8;
  z-index: 1200;
}
.cover{
  object-fit: cover;
}
// img.el-image__inner{
//   background: #1c418500;
// }

/*定义滚动条样式*/
// ::-webkit-scrollbar{
//   width:.2rem;
//   height:.2rem;
//   background-color:rgba(1, 17, 27, 0.8);
//   // background-color:#0f7dbdde;
//   }
// /*定义滚动条轨道内阴影+圆角*/
// ::-webkit-scrollbar-track{
//   box-shadow:inset000pxrgba(240,240,240,.5);
//   border-radius:10px;
//   background-color:rgba(1, 17, 27, 0.8);
//   }
// /*定义滑块内阴影+圆角*/
// ::-webkit-scrollbar-thumb{
//   border-radius:10px;
//   box-shadow:inset000pxrgba(240,240,240,.5);
//   background-color:rgba(65, 71, 107, 0.8);
//   }

</style>
